.verify-container{
    background-color: white;
    min-height: 300px;
    .verify-wrapper{
    display: grid;
    place-content: center;
    text-align: center;
    margin-top: 40px;
    margin:0 auto;
    margin-top: 40px;
    padding: 20px;
    span{
        font-weight: bold;
        justify-self: center;
        width: 400px;
        @media screen and (max-width: 700px) {
            width: 300px;
        }
    }
    .verify-dialog{
        font-weight: 400;
        color: #b4b4b4;
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 18px;
    }
    form{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
         width: 400px;
         gap: 10px;
         margin-bottom: 200px;
         justify-self: center;
         @media screen and (max-width: 700px) {
            width: 300px;
         }
         .otp_holder{
            border: 2px solid #CFCFCF;
            border-radius: 5px;
            padding: 20px;
            height: 85px;
            input{
                padding:2px;
                border: none;
                text-align: center;
                border-radius: 4px;
                border: none;
                height: 36px;
                &:focus{
                    outline: none;
                }
             }
             fieldset{
                border: none;
                border-bottom: 1px solid #2CB5B2;
                border-radius: 0;
             }
             
         }
        
         button{
            background-color: #2CB5B2;
            border-radius: 4px;
            padding:10px;
            border: none;
            color: white;
            cursor:pointer;
            font-size: 16px;
            font-weight: 700;
         }
         .resend{
            font-size: 16px;
            .resend-span{
                text-decoration: underline;
                font-weight: 400;
                cursor: pointer;
                color: #2CB5B2;
                @media screen and (max-width: 700px) {
                    display: block;
                }
            }
         }
    }
    .customer_otp{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
         width: 400px;
         gap: 10px;
         margin-bottom: 200px;
         justify-self: center;
         @media screen and (max-width: 700px) {
            width: 300px;
         }
         .otp_holder{
            border: 2px solid #CFCFCF;
            border-radius: 5px;
            padding: 20px;
            height: 85px;
            input{
                padding:2px;
                border: none;
                text-align: center;
                border-radius: 4px;
                border: none;
                height: 36px;
                &:focus{
                    outline: none;
                }
             }
             fieldset{
                border: none;
                border-bottom: 1px solid #2CB5B2 !important;
                border-radius: 0;
             }
             
         }
        
         button{
            background-color: #2CB5B2 !important;
            border-radius: 4px;
            padding:10px;
            border: none;
            color: white;
            cursor:pointer;
            font-size: 16px;
            font-weight: 700;
         }
         .resend{
            font-size: 16px;
            .resend-span{
                text-decoration: underline;
                font-weight: 400;
                cursor: pointer;
                color: #2CB5B2  !important  ;
                @media screen and (max-width: 700px) {
                    display: block;
                }
            }
         }

    }
    }
}