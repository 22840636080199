.clinic-dashboard-container {
  color: #2a2a2a;
  display: flex;
  position: relative;
  .sidebar-container {
    min-width: 350px;
    max-width: 350px; 
    background: #2CB5B2;
    color: white;
    padding: 30px;
    padding-top: 80px;
    @media screen and (max-width: 700px) {
      display: none;
    }
    &.customer-container{
      @media screen and (max-width: 1024px) {
        display: none !important;
      }
    }
    .sticky_container {
      position: sticky;
      top: 85px;
      h1 {
        text-align: center;
        font-size: 25px;
        font-weight: 600px;
        margin-bottom: 45px;
        font-family: "Poppins", open sans;
        font-size: 30px;
        font-weight: 600;

      }

      .menu-button {
        border: 1px solid white;
        padding: 8px;
        border-radius: 5px;
        margin-bottom: 30px;
        cursor: pointer;
        min-height: 52px;
        display: grid;
        &.menu_center {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .menu {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h4 {
            text-align: center;
            font-weight: 400;
            justify-self: center;
          }
        }
        .sub-menu {
          border-top: 2px solid white;
          margin-top: 10px;
          padding-top: 10px;
          display: flex;
          gap: 8px;
          justify-content: center;
          flex-direction: column;
          transition: all 0.5s ease;
          a {
            text-decoration: none;
            color: white;
            text-align: center;
          }
        }
      }
      .active {
        h4 {
          color: #8b7bcb;
          &.h4-exp {
            color: #2CB5B2 !important;
          }
        }
      }

      .active-menu {
        padding: 0;
        padding-bottom: 8px;
        .h4-active {
          color: #2CB5B2 !important;
          background-color: white;
          text-align: center;
          width: 100%;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: -8px;
        }
        .sub-menu {
          border-top: none !important;
        }
      }

      .logout {
        display: flex;
        gap: 10px;
        align-items: center;
        font-size: 16px;
        cursor: pointer;
        h4 {
          font-weight: 400;
        }
        .lg_svg {
          color: white !important;
        }
      }
      a {
        text-decoration: none;
        color: white;
        &.active {
          background-color: white;
          color: #2CB5B2;
        }
      }
    }
  }
  .content-container {
    flex: 1;
    padding: 103px;
    padding-top: 80px;
    width: calc(100vw - 350px);
    input{
      color: #2a2a2a;
      font-family: "Poppins", open sans;
      font-weight: 400;
      font-size: 18px !important;

    }
    h1{
      font-family: "Poppins", open sans;
      font-size: 30px;
      font-weight: 600;
    }
    select{
      color: #2a2a2a;
      font-family: "Poppins", open sans;
      font-weight: 400;
      font-size: 18px !important;
    }
    

    textarea{
      font-size: 18px !important;
    }
    button{
      font-family: "Poppins", open sans;
      font-weight: 700;
    }
    label{
      font-family: "Poppins", open sans;
      font-weight: 400;
      font-size: 18px !important;
    }
    ::placeholder {
      color: #757575;
      font-family: "Poppins", open sans;
      font-weight: 400;
      
    } 
    

    @media screen and (max-width: 700px) {
      padding: 12px;
      padding-top: 80px;
      width: 100vw;
      padding-bottom: 60px;
    }
    &.customer-content-container{
      @media screen and (max-width: 1024px) and (min-width: 701px) {
        width: 100vw;
      }
    }
    
    .welcome-title {
      font-size: 25px;
      color: #2a2a2a;
      span {
        color: #2CB5B2;
      }
    }
    .page-name {
      color: #2CB5B2;
      font-size: 25px;
      font-weight: 600px;
      margin-bottom: 15px;
      &.sm-page-name {
        font-size: 25px;
        margin-bottom: 20px;
        margin-top: 54px;
      }
    }
    .page-image-container-page {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 25px;
      .pet-data {
        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;

        .img-holder {
          height: 80px;
          width: 80px;
          border-radius: 50%;
          background: #eeeeee;
          display: flex;
          place-content: center;
          cursor: pointer;
          position: relative;
          align-items: center;
          justify-content: center;

          img {
            object-fit: cover;
            width: 100%;
            border-radius: 50%;
            height: 100%;
            &.addIcon {
              height: 30px;
              width: 30px;
            }
            &.full-image {
              height: 100%;
              width: 100%;
            }
          }
        }
        .add-label {
          margin-top: 8px;
          color: #2CB5B2;
          font-size: 18px;
          font-weight: 700px;
          text-align: center;
          font-weight: bold;
          cursor: pointer;
        }
        span {
          margin-top: 8px;
          color: #2CB5B2;
          font-size: 14px;
          cursor: pointer;
          font-weight: 700px;
          text-align: center;
          font-weight: bold;
        }
      }
    }
    .page-image-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 40px;
      .pet-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        .img-holder {
          height: 80px;
          width: 80px;
          border-radius: 50%;
          background: #eeeeee;
          display: flex;
          place-content: center;
          cursor: pointer;
          position: relative;
          align-items: center;
          justify-content: center;

          img {
            height: 100%;
            object-fit: cover;
            width: 100%;
            border-radius: 50%;
            &.addIcon {
              height: 30px;
              width: 30px;
            }
            &.full-image {
              height: 100%;
              width: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }
        }
        .add-label {
          margin-top: 8px;
          color: #2CB5B2;
          font-size: 18px;
          font-weight: 700px;
          text-align: center;
          font-weight: bold;
          cursor: pointer;
        }
        span {
          margin-top: 8px;
          color: #2CB5B2;
          font-size: 14px;
          cursor: pointer;
          font-weight: 700px;
          text-align: center;
          font-weight: bold;
        }
      }
    }
    .remarks {
      color: #2a2a2a;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 60px;
      max-width: 599px;
      margin-top: 33px;
    }
    .rendezvous {
      margin-top: 54px;
      .rendezvous-title {
        color: #2CB5B2;
        margin-bottom: 40px;
        font-weight: 600;
        font-size: 25px;
      }
      .rendezvous-detail {
        justify-content: space-between;
        @media screen and (max-width: 650px) {
          align-items: baseline;
        }
        .detail {
          ul {
            list-style: none;

            li {
              margin: 10px 0;
              display: flex;
              font-weight: bold;
              min-height: 35px;
              svg {
                color: #2CB5B2;
                font-weight: 900;
                margin-right: 15px;
              }
              &.prev-rendezvous {
                border: 1px solid #2CB5B2;
                padding: 8px;
                cursor: pointer;
                width: 300px;
              }
              &.previci-rendezvous {
                background: #2CB5B2;
                padding: 8px;
                cursor: pointer;
                width: 300px;
                color: whitesmoke;
              }
            }
          }
        }
      }
      button {
        width: 300px;
        padding: 8px;
        min-height: 54px;
        background-color: white;  
        border: 1px solid #2CB5B2;
        font-size: 14px;
        font-weight: bold;
        margin-top: 20px;
        cursor: pointer;
        @media screen and (max-width: 1250px){
          width: 100% !important;
        }
      }
    }
    .rendezvous-passes {
      .rendezvous-title {
        color: #2CB5B2;
        margin-bottom: 40px;
        font-weight: 600;
        font-size: 25px;
        margin-top: 40px;
      }
      .rendezvous_item {
        margin-bottom: 25px;
        border: 1px solid #2CB5B2;
        padding: 12px;
        min-height: 160px;
        display: flex;
        border-radius: 5px;
        justify-content: space-between;
        font-size: 14px;
        gap: 40px;
        @media screen and (max-width: 1250px) {
          flex-direction: column;
          align-items: baseline;
          gap: 0;
        }
        .rendezvous_img {
          // display: grid;
          // place-content: center;
          width: 300px;
          .img_container {
            height: 80px;
            width: 80px;
            border-radius: 50%;
            border: 2px solid #1ca5a3;
            img {
              height: 100%;
              object-fit: cover;
              width: 100%;
              border-radius: 50%;
            }
          }
        }
        .rendezvous_address{
          width: 300px;
        }
        .item_data {
          width: 100%;
          text-align: justify;
          display: flex;
          align-items: center;
          margin-top: 20px;
          font-weight: bold;
          .changeitemvalue {
            display: flex;
            align-items: center;
            padding: 5px;
            height: 52px;
            border: 1px solid #1ca5a3;
            border-radius: 5px;
            width: 250px;
            select {
              height: 100%;
              width: 100%;
              border: none;
              outline: none;
              cursor: pointer;
            }
            input {
              height: 100%;
              width: 100%;
              border: none;
              outline: none;
              cursor: pointer;
            }
          }
          .edititemvalue {
            display: flex;
            align-items: center;
            padding: 5px;
            height: 52px;
            width: 250px;
            select {
              height: 100%;
              width: 100%;
              border: none;
              outline: none;
              cursor: pointer;
            }
            img {
              height: 20px;
              width: 20px;
              margin-right: 6px;
              cursor: pointer;
            }
            span {
              color: red;
              font-weight: lighter;
              cursor: pointer;
            }
          }
          svg {
            color: #2CB5B2;
            margin-right: 10px;
            font-size: 22px;
          }
        }
        button {
          width: 100%;
          padding: 8px;
          min-height: 54px;
          background-color: #2CB5B2;
          border: none;
          font-weight: bold;
          font-size: 14px;
          color: whitesmoke;
          margin-top: 20px;
          cursor: pointer;
          font-family: "Poppins", open sans;
        }
        .rendezvous_action {
          width: 300px;
          @media screen and (max-width: 1250px) {
            width: 100%;
          }
          button {
            width: 100%;
            padding: 8px;
            min-height: 54px;
            background-color: #2CB5B2;
            border: none !important;
            font-size: 14px;
            font-weight: bold;
            color: whitesmoke !important;
            margin-top: 20px;
            cursor: pointer;
            @media screen and (max-width: 700px) {
              width: 100% !important;
            }
            @media screen and (max-width: 1250px){
              width: 100% !important;
            }
          }
        }
      }
      .btn_action {
        width: 300px;
        padding: 8px;
        min-height: 54px;
        border: 1px solid #2CB5B2;
        font-weight: bold;
        font-size: 14px;
        color: black;
        font-family: "Poppins", open sans;
        background-color: transparent;
        margin-top: 20px;
        cursor: pointer;
        @media screen and (max-width: 700px) {
          width: 100% !important;
        }
       
      }
      .filter-container {
        min-height: 142px;
        margin-bottom: 40px;
        border-radius: 5px;
        background: #2CB5B233;
        padding: 20px;

        h1 {
          color: #2CB5B2;
          font-size: 18px;
          font-weight: 600px;
          margin-bottom: 20px;
        }
        .filter-item {
          display: flex;
          gap: 22px;
          @media screen and (max-width: 1000px) {
            flex-direction: column;
            gap: 10px;
          }

          .search-inputs {
            display: flex;
            align-items: center;
            padding: 5px;
            width: 252px;
            height: 43px;
            background-color: white;
            border: 1px solid #1ca5a3;
            border-radius: 5px;
            @media screen and (max-width: 1000px) {
              width: 100%;
              margin-bottom: 20px;
            }
            input {
              height: 100%;
              width: 100%;
              border: none;
              outline: none;
              border-radius: 5px;
              background-color: white;
              color: black;
            }
            input[type="date"]::-webkit-inner-spin-button,
            input[type="date"]::-webkit-calendar-picker-indicator {
              display: none;
              -webkit-appearance: none;
            }
            svg {
              color: #2CB5B2 !important;
            }
          }
        }
      }
    }

    .my-oldemail {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 40px;
      svg {
        color: #2CB5B2;
        font-weight: 900;
      }
      .admin_svg {
        color: #8b7bcb;
      }
    }
    .change-form {
      margin-bottom: 40px;
      form {
        .form-subtitle {
          color: #2CB5B2;
          font-weight: 600;
          font-size: 25px;
          margin-top: 50px;
          margin-bottom: 20px;
        }
        .img_holder {
          display: flex;
          gap: 8px;
          div {
            height: 80px;
            width: 80px;
            border-radius: 5px;
            &.img_container {
              padding: 3px;
              border: 1px solid #1ca5a3;

              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 5px;
              }
            }
            &.img_add {
              background-color: #f1f1f1;
              display: grid;
              place-content: center;
              cursor: pointer;
              svg {
                font-size: 50px;
                cursor: pointer;
              }
            }
          }
        }
        .gap-10 {
          gap: 30px;
          align-items: center;
          @media screen and (max-width: 750px) {
            gap: 0px;
          }
          .input-container {
            justify-content: space-between;
            flex: 1;
            &.input-container-2 {
              margin-bottom: 24px;
            }
            @media screen and (max-width: 800px) {
              align-items: baseline;
              width: 100%;
            }
            label {
              display: block;
              font-size: 16px;
              margin-bottom: 10px;
              font-weight: 400;
              color: #2CB5B2;
            }
            input {
              width: 100%;
              padding: 10px !important;
              border: 1px solid #2CB5B2;
              border-radius: 5px;
              outline: none;
              height: 52px;
              color: black;
              background: white;
              &.phoneCode{
                padding-left: 45px !important;
              }
            }
            textarea {
              min-width: 100%;
              width: 100%;
              padding: 10px;
              border: 1px solid #2CB5B2;
              border-radius: 5px;
              outline: none;
              max-height: 188px;
              min-height: 188px;
              background: white;
              color: black;
              resize: none;
            }
            select {
              width: 100%;
              padding: 10px;
              border: 1px solid #2CB5B2;
              border-radius: 5px;
              outline: none;
              height: 52px;
              background: white;
              color: black;
            }
            .link_container {
              display: flex;
              width: 100%;
              align-items: center;
              padding: 10px;
              border: 1px solid #2CB5B2;
              border-radius: 5px;
              outline: none;
              height: 52px;
              padding-right: 0;
              svg {
                color: #2CB5B2;
              }
              input {
                border: none;
                height: 95%;
              }
            }
          }
        }
        .btn-container {
          strong {
            margin-right: 4px;
          }
          button {
            margin-top: 10px;
            padding: 12px;
            border-radius: 5px;
            outline: none;
            cursor: pointer;
            border: none;
            background: #2CB5B2;
            color: whitesmoke;
            font-weight: 600;
          }
        }
      }
    }
    .delete-account {
      color: red;
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
    }
    .pet-profile {
      display: flex;
      gap: 30px;
      margin-top: 54px;
      .profile-img-container {
        height: 100px;
        width: 100px;
        border-radius: 50px;
        border: 2px solid #1ca5a3;
        background: #eeeeee;
        img {
          height: 100%;
          width: 100%;
          border-radius: 50px;
          object-fit: cover;
        }
      }
      .profile-data {
        ul {
          list-style: none;
          li {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 10px;
            font-weight: 500;
            text-transform: capitalize;
            color: #2a2a2a;
            img {
              width: 20px;
            }
          }
        }
      }
    }
  }
}

.mobo_nav_container {
  height: 65px;
  align-items: center;
  background: #2CB5B2;
  position: sticky;
  top: 80px;
  z-index: 10;
  display: none;
  width: 100%;
  @media screen and (max-width: 700px) {
    display: flex;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    li {
      list-style: none;
      cursor: pointer;
      opacity: 0.5;
      &.active {
        opacity: 1;
        border: 1px solid white;
        padding: 2px;
        border-radius: 5px;
        animation: borderanime alternate 1s ease-in-out infinite;
        @keyframes borderanime {
          from {
            border: 1px solid white;
          }
          to {
            border: 1px solid transparent;
          }
        }
      }
      a {
        display: block;
        text-decoration: none;
        width: 30px;
        height: 30px;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }
}

.tablet-menu{
  padding: 4px;
  align-items: center;
  background: #2CB5B2;
  position: sticky;
  top: 80px;
  z-index: 10;
  display: none;
  width: 100%;
  @media screen and (max-width: 1024px) and (min-width: 701px) {
    display: block;
  }
  h1{
    font-family: "Poppins", open sans;
    font-size: 30px;
    font-weight: 600;
    color: white;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap:8px;
    width: 100%;
    height: 65px;
    li {
      list-style: none;
      cursor: pointer;
      border: 1px solid white;
      &.active {
        opacity: 1;
        border: 1px solid white;
        padding: 2px;
        background: white;
        span{
          color: #2CB5B2;
          font-weight: 400;

        }       
      }
      a {
        display: flex;
        text-decoration: none;
        align-items: center;
        gap: 5px;
        padding: 3px 8px;
        .img-container{
          height: 29px;
          width: 30px;
          img {
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
        }
        
        span{
          color: white;
          size: 14px;
        }
      }
    }
  }
}


.phoneCode{
  padding-left: 45px !important;
}
