.register-container{
    background-color: white;
    color: black;
    padding-bottom: 100px;
    h1{
        font-size: 20px;
        text-align: center;
        color: #2CB5B2;
        font-weight: bold;
    }
    .p-description{
      
        margin-top: 45px;
        margin-bottom:45px;
    }
    .register-inner-wrapper{
        width: 700px;
        margin: 0 auto;
        padding-top: 40px;
        @media screen and (max-width:650px) {
            width: 80%;
        }
         .top-form-title{
        text-align: center;
        font-weight: bold;
        .span-colored{
            color: #2CB5B2;
        }
    }
        form{
         width: 100%;
         margin-top: 30px;
        color: #2CB5B2;
      

    .input-container{
        
          gap: 20px;
           @media screen and (max-width:650px) {
            flex-direction:column !important;
          gap: 10px;

        }
        .input-holder{
        width: 100%;
            input{
                    margin-top: 8px !important;
                    width: 100%;
                    margin-bottom: 22px;
                    padding: 8px;
                    background-color: transparent;
                    color: #2a2a2a;
                    border: 1px solid #2CB5B2;
                    border-radius: 5px;
                    height: 52px;
                    &:focus{
                        outline: none;
                    }
                    &::placeholder{
                        color: #2a2a2a;
                    }
                    &:hover{
                        &::placeholder {
                          color: rgba(0,0,0,0.6);
                        }
                    }
                }
                .vb_item{
                    border: 1px solid;
                    margin-top: 8px !important;
                    width: 100%;
                    margin-bottom: 22px;
                    height: 52px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    svg{
                        margin-left: 8px;
                    }
                    .vb_icon{
                         display: flex;
                         align-items: center;
                         padding-right: 8px;
                         cursor: pointer;
                    }
                    input{
                        border: none;
                        margin: 0;
                        margin-top: 0 !important;
                        height: 100% !important;
                        
                    }
                }
                .terms-check{
                    margin-top: 8px;
                    margin-bottom: 7px;
                    color: black;
                    display: flex;
                    gap: 5px;
                    .checkbox-group{
                        display: flex;
                        gap: 20px;
                        @media screen and (max-width:500px) {
                            flex-direction: column;
                        }
                        label{
                            font-weight: 500px;
                        }
                        .checkbox_wrapper{
                            display: flex;
                            gap: 8px;
                            align-items: center;
                            position: relative;
                            cursor: pointer;
                            
                            .pet_checkbox{
                                height: 14px !important;
                                border-radius: 5px !important;
                                border: 1px solid #2CB5B2;
                                display: inline !important;
                                min-width: 14px !important;
                               
                            }
                            span{
                                font-weight: 500;
                            }
                            .flag{
                                display: flex;
                                align-items: center;
                                img{
                                    position: inherit;
                                    height: 24px;
                                    object-fit: cover;
                                    margin-right: 4px;
                                }
                            }
                            img{
                                 height: 10px;
                                 object-fit: cover;
                                 position: absolute;
                                 left: 2px;
                                 top: 0px;
                                 display: none;
                            }
                            .pet_hidden_check:checked ~ img{
                                display: block;
                            }
                        }
                }
                    @media screen and (max-width:650px) {
                        display: flex;
                        align-items: baseline
                      }
                     .chech-terms{
                      margin: 0;
                      height: 14px;
                      width: 20px;
                      margin-top: 0 !important;
                      &[type="checkbox"]{
                      border: 1px solid #2CB5B2 !important;
                    }
                    }
                              div{
                    }
                }
               
        }
        .submit-button{
            text-align: center;
               button{    
                    margin-top: 20px;
                    min-width: 100px;
                    padding: 12px;
                    border-radius: 4px;
                    border: none;
                    font-weight: bold;
                    font-size: 14px;
                    background-color: #2CB5B2;
                    color: white;
                    cursor: pointer;
                    align-self: center;
                    @media screen and (max-width:650px){
                        width: 100%;
                    }
                }

        }     
            }
    .input-container-style{
        background: #2CB5B2;
        color: white;
        padding: 6px;
        font-size: 16px;
        border-radius: 5px;
        margin-bottom: 30px;

        .clinicId{
            background: white;
            color: black;
            display: flex;
            align-items: baseline;
            padding: 8px;
            margin-top: 8px;
            border-radius: 5px;
            margin-bottom: 10px;
            .clinicId-input{
                padding: 10px;
                height: unset;
                margin: 0 !important;
                border: none;

            }
        }
        p{
            text-align: justify;
        }
    }
        }
    }
}

.authbtn-group{
    max-width: 530px;
    margin: 0 auto;
    button{
        align-self: center;
        color: #0000008A;
        font-size: 16px;
    }
    hr{
        width: 100%;
        border: 1px solid #2CB5B2;
        margin-bottom: 14px;
        margin-top: 10px;
        opacity: 0.4;
    }
    p{
        font-size: 14px;
        color: #2A2A2A;
        font-weight:400;
        margin-bottom: 3px;
        text-align: start;
        span{
            cursor: pointer;
        }
    }
    .separator{
        text-align: center;
        margin-bottom: 17px;
        font-size: 20px;
        font-weight: 700;
        line-height: 27px;
        letter-spacing: 0em;
    }
    .other-login4{
        background-color: #2CB5B2;
        color: white;
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;

    }

}