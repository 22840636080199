.register-container{
    background-color: #2CB5B2;
    padding-bottom: 100px;
    h1{
        font-size: 20px;
    }
    .register-inner-wrapper{
        width: 600px;
        margin: 0 auto;
        padding-top: 30px;
        @media screen and (max-width:650px) {
            width: 80%;
        }
        form{
         width: 100%;
         margin-top: 30px;
    .input-container{
          gap: 20px;
           @media screen and (max-width:650px) {
            flex-direction:column !important;
          gap: 10px;

        }
        .input-holder{
        width: 100%;
            input{
                    margin-top: 8px !important;
                    width: 100%;
                    margin-bottom: 22px;
                    padding: 8px;
                    background-color: transparent;
                    color: white;
                    border: 1px solid whitesmoke;
                    &:focus{
                        outline: none;
                    }
                }
            .vb_item{
                border: 1px solid;
                margin-top: 8px !important;
                width: 100%;
                margin-bottom: 22px;
                height: 52px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                svg{
                    margin-left: 8px;
                }
                .vb_icon{
                    display: flex;
                    align-items: center;
                    padding-right: 8px;
                    cursor: pointer;
               }
                input{
                        border: none;
                        margin: 0;
                        margin-top: 0 !important;
                        height: 100% !important;
                }
            }
                .terms-check{
                    margin-top: 8px;
                    margin-bottom: 7px;
                    .checkbox-group{
                        display: flex;
                        gap: 20px;
                        @media screen and (max-width:500px) {
                            flex-direction: column;
                        }
                        label{
                            font-weight: 500px;
                        }
                        .checkbox_wrapper{
                            display: flex;
                            gap: 8px;
                            align-items: center;
                            position: relative;
                            cursor: pointer;
                            
                            .pet_checkbox{
                                height: 14px !important;
                                border-radius: 5px !important;
                                border: 1px solid #2CB5B2;
                                display: inline !important;
                                min-width: 14px !important;
                               
                            }
                            span{
                                font-weight: 500;
                            }
                            .flag{
                                display: flex;
                                align-items: center;
                                img{
                                    position: inherit;
                                    height: 24px;
                                    object-fit: cover;
                                    margin-right: 4px;
                                }
                            }
                            img{
                                 height: 10px;
                                 object-fit: cover;
                                 position: absolute;
                                 left: 2px;
                                 top: 0px;
                            }
                            .pet_hidden_check:checked ~ img{
                                display: none;
                            }
                        }
                }
                    input{
                      margin: 0;
                      width: 10%;  
                    }
                    display: flex;
                    align-items: baseline;
                              div{
                        flex: 1;
                    }
                }
               
        }
         button{
                    margin-top: 10px;
                    min-width: 100px;
                    padding: 10px;
                    border-radius: 4px;
                    border: none;
                    font-weight: bold;
                    font-size: 14px;
                    background-color: white;
                    color: #2CB5B2;
                    cursor: pointer;
                     @media screen and (max-width:650px){
                        width: 100%;
                    }
                }
               
            }
        }
    }
}