.footer-container{
    background-color: #25262a;
    min-height: 300px;
    padding:30px 0px;
    .top-container{
        width:  70vw;
        margin: 0 auto;
        margin-bottom: 40px;
        gap: 100px;
         @media screen and (max-width:800px) {
          gap: 20px;
  }
       .column-width{
           width: 300px; 
        .footer-section{
            margin-bottom: 50px;  
            @media screen and (max-width:800px) {
                margin-bottom: 30px;  
            }
            &.web{
                @media screen and (max-width:800px) {
                    display: none;
                    
                }
            }
            &.mobo{
                display: none;
                @media screen and (max-width:800px) {
                    display: block;
                    
                }
            }
            .spacing
            {
                margin-top: 73px;
                @media screen and (max-width:800px) {
                    margin-top: 0px;
                }
            }
            .spacing_sm
            {
                padding-bottom: 27px !important;
            }
           .footer-title{
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
        }
         p{
            color: whitesmoke;
            font-size: 14px;
        }
        a{
            text-decoration: none;
            color: whitesmoke;
            font-size: 14px;
            display: block;
            cursor: pointer;
            margin-bottom: 3px;
        }
         form{
                .send-input{
                    width:293px;
                    height: 40px;
                    border: 2px solid #2CB5B2;
                    display: flex;
                    input{
                        height: 100%;
                        background: transparent; 
                        outline: none;
                        border: none;
                        padding: 5px;
                        color: white;
                        outline:none;
                        width:100%;
                    }
                    button{
                        width: 50px;
                        border: none;
                        background: #2CB5B2;
                        color: white;
                        cursor: pointer;
                        svg{
                            font-size: 20px;
                        }
                    }
                }
            }
         ul{
            list-style: none;
            li{
                display: flex;
                align-items: center;
                font-size: 14px;
                color:whitesmoke;
                margin-bottom: 8px;
                svg{
                    font-size: 20px;
                    color:#2CB5B2; 
                    margin-right: 8px;
                }
            }
         }
         .brands-container{
            gap: 10px !important;
            justify-content: flex-start !important;
            @media screen and (max-width:1000px) {
                flex-direction: row !important;
            }
           .brands-icon{
            i{
                font-size: 14px;
                color: #2CB5B2;
                cursor: pointer;
            }
         }
         }
        
        }
       
       }
       .column-one{
        .img-container{
            margin-bottom: 10px !important;
            img{
          height: 100%;
          width: 150px;  
        }
        }
       }
    }
    .bottom-container{
       display: flex;
       align-items: center;
       justify-content: center;
       font-size: 14px;
       padding-top:30px;
       color:whitesmoke;
       text-align: center;
       margin-left: 20px;
       margin-right: 20px;
       h4{
        font-size: 14px;
        font-weight: 200;
        span{
            font-weight: bold  !important;
            a{
                text-decoration: none;
                cursor: pointer;
                color: white;
            }
        }
       }
    }
    hr{
    height: 1px;
    background: #4c3d3d7a;
    border: none;
    }
}