@tailwind base;
@tailwind components;
@tailwind utilities;

p{
    .symbol{
        color: #2CB5B2;
    }
}
.symbol-detail{
    margin-top: 20px;
}

